import { initializeApp } from "firebase/app";
import { initializeFirestore, getFirestore } from "firebase/firestore";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";

const config = {
  apiKey: "AIzaSyAKQUl3X_MsB--NC2mmBv1QDlkYedesjyM",
  authDomain: "e-commerce-9e6dc.firebaseapp.com",
  databaseURL: "https://e-commerce-9e6dc.firebaseio.com",
  projectId: "e-commerce-9e6dc",
  storageBucket: "e-commerce-9e6dc.appspot.com",
  messagingSenderId: "215401701740",
  appId: "1:215401701740:web:1c4078516bb4ba942f5349",
  measurementId: "G-45QGGVYL1N",
};

const firebaseApp = initializeApp(config);

const firestore = getFirestore(firebaseApp);

// Initialize Auth instance
const auth = getAuth(firebaseApp);

// Set up Google Auth Provider and configure prompt
export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => signInWithPopup(auth, googleProvider);

export const createUserProfileDocument = async (userAuth, additionalData) => {
  // 1. Exit if user signed out
  if (!userAuth) return;

  // Create a reference to the user's document in Firestore using their unique uid (automatically generated by Firestore upon user authentication)
  const userRef = doc(firestore, `users/${userAuth.uid}`);

  // 2. Retrieve the document snapshot to check if the user document already exists
  const snapShot = await getDoc(userRef);

  // 3. If user document does not exist, create a new user document with additional data
  if (!snapShot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    const favorites = null;

    try {
      await setDoc(userRef, {
        displayName,
        email,
        createdAt,
        favorites,
        ...additionalData,
      });
    } catch (error) {
      console.error("Error creating user", error.message);
    }
  }
  return userRef;
};

// Retrieve or create a reference to the user's cart
export const getUserCartRef = async (userId) => {
  const cartsQuery = query(
    collection(firestore, "carts"),
    where("userId", "==", userId)
  );
  const snapShot = await getDocs(cartsQuery);

  if (snapShot.empty) {
    // Create document reference object and initialize cartItems as empty array
    const cartDocRef = doc(collection(firestore, "carts"));
    await setDoc(cartDocRef, { userId, cartItems: [] });
    return cartDocRef;
  } else {
    // or return reference to cart
    return snapShot.docs[0].ref;
  }
};

export const addItemToFavorites = async (currentUser, itemToAdd, favorites) => {
  const userRef = doc(firestore, `users/${currentUser.id}`);

  try {
    await updateDoc(userRef, {
      favorites: { ...favorites, [itemToAdd.id]: itemToAdd },
    });
  } catch (error) {
    console.error("Error adding favorite", error.message);
  }
};

export const removeItemFromFavorites = async (
  currentUser,
  itemToRemove,
  favorites
) => {
  const userRef = doc(firestore, `users/${currentUser.id}`);
  const favoritesCopy = { ...favorites };
  delete favoritesCopy[itemToRemove.id];

  try {
    await updateDoc(userRef, {
      favorites: favoritesCopy,
    });
  } catch (error) {
    console.error("Error removing favorite", error.message);
  }
  return favoritesCopy;
};

// Add collection and documents to Firestore
export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  // Create a reference to the specified collection in Firestore
  const collectionRef = collection(firestore, collectionKey);

  // Create a Firestore batch object to perform multiple write operations at once
  const batch = writeBatch(firestore);

  objectsToAdd.forEach((obj) => {
    // Create a new documentReference object with a randomly generated ID for each object in the collection
    const newDocRef = doc(collectionRef);

    // Add the object to the batch with the new document reference
    batch.set(newDocRef, obj);
  });

  // 6. Commit the batch to execute all the write operations
  return await batch.commit();
};

// Extract the first word in a string
const extractFirstWord = (string) => string.replace(/ .*/, "").toLowerCase();

// Transform Firestore collections data into a usable format
export const transformCollectionsData = (collections) => {
  const transformedCollection = collections.docs.map((doc) => {
    const { title, subtitle, items, banner, collectionId } = doc.data();
    const routeName = encodeURI(extractFirstWord(title));
    return {
      routeName,
      id: collectionId,
      title,
      subtitle,
      items,
      banner,
    };
  });

  return transformedCollection.reduce((accumulator, collection) => {
    accumulator[extractFirstWord(collection.title)] = collection;
    return accumulator;
  }, {});
};

// Transform a single Firestore document snapshot into a formatted object
export const transformCollectionData = (snapshot) => {
  const collectionDoc = snapshot.docs[0];
  if (collectionDoc) {
    const { title, subtitle, items, banner, collectionId } =
      collectionDoc.data();
    const routeName = encodeURI(extractFirstWord(title));

    return {
      [collectionId]: {
        id: collectionId,
        title,
        subtitle,
        items,
        banner,
        routeName,
      },
    };
  }
  return null;
};

// Get the current authenticated user
export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    // onAuthStateChanged observes the Auth object, monitoring changes to the user's sign-in state.
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        // Unsubscribe immediately after getting the user to avoid keeping the listener active unnecessarily.
        unsubscribe();
        // Resolve the promise with the userAuth object if a user is signed in.
        resolve(userAuth);
      },
      // If there's an error, the promise is rejected with the error.
      reject
    );
  });
};

// Export Firestore and Auth instances
export { firestore, auth };
